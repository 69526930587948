import React, { useState } from "react";
import "./Visor.scss";
import * as funciones from "./funcionesVisor";

import Main from "./Main";
import Drawer from "./Drawer";
import Pies from "./Pies";
import Cabeza from "./Cabeza";
import EstudioBox from "./EstudiosBox";
import { HerramientasDicomM, HerramientasDicomD } from "./Herramisntas";
import { verAlerta } from "./funcionesMain";
import Dialog from "./Dialog";
import { useEffect } from "react";

let HerMob = new HerramientasDicomM("Wwwc");
let HerDesI = new HerramientasDicomD("Wwwc");
let HerDesS = new HerramientasDicomD("ZoomMouseWheel");
let HerDesD = new HerramientasDicomD("Length");

function Visor(props) {
  const [Estudio] = useState(
    JSON.parse(window.sessionStorage.getItem("EstudioVisor"))
  );
  const [EstudioSeleccionado, setEstudioSeleccionado] = useState(0);
  const [DrawerVisible, setDrawerVisible] = useState(false);
  const [DrawerCont, setDrawerCont] = useState("EstudioBox");
  const [VerInforme, setVerInforme] = useState({
    existe: false,
    visible: false,
    nombre: "",
  });
  const [PdfBlob, setPdfBlob] = useState()
  const [Cinema, setCinema] = useState({ go: false });
  const [HerrMobil, setHerrMobil] = useState(HerMob);
  const [HerrDes, setHerrDes] = useState([HerDesI, HerDesS, HerDesD]);
  const [PrintCode, setPrintCode] = useState(undefined)
  let VerDrawer = (valor) => {
    if (valor === undefined) {
      let nuevovalor = DrawerVisible ? false : true;
      setDrawerVisible(nuevovalor);
    } else {
      setDrawerVisible(valor);
    }
  };
  let SeleccionarEstudio = (e) => {
    setEstudioSeleccionado(e);
    setTimeout(() => {
      setDrawerVisible(false);
    }, 250);
  };
  let PiesSistemAction = (e, f) => {
    if (e) {
      if (f === "VerDrawer") {
        VerDrawer();
      }
      if (f === "Cinema") {
        let cine = Cinema;
        cine.go = cine.go ? false : true;
        setCinema({ ...cine });
      }
    }
  };
  let onPlay = () => {
    let cine = Cinema;
    cine.go = cine.go ? false : true;
    setCinema({ ...cine });
  };
  let onToolSelecctionMob = (e, nombre) => {
    let CambioHer = HerMob.cambiarHerramienta(HerMob, nombre);
    //console.log(CambioHer)
    setHerrMobil({ ...CambioHer });
  };
  let CinemaCrash = () => {
    let v = Cinema;
    v.go = false;
    setCinema({ ...v });
  };
  let HerrDesSel = (herramienta) => {
    if (herramienta.nombre === "Magnify" && Cinema.go === true) {
      verAlerta("Modo Cine Activo", "Peligro");
    } else {
      let nuevaHer = HerrDes;
      let cambio = nuevaHer[herramienta.index];
      let cambiado = cambio.cambiarHerramienta(herramienta.nombre);
      nuevaHer[herramienta.index] = cambiado;
      setHerrDes([...nuevaHer]);
    }
  };
  //////////////////INFORME////////////////////////
  let ShowInf = () => {
    //let a = VerInforme;
    //a.visible = VerInforme.visible ? false : true;
    //console.log(PdfBlob);
    //convert PdfBlob to url
    if(PdfBlob){
        let url = URL.createObjectURL(PdfBlob)
        window.open(url, "_blank")
    }

  };
  let ContenidoDrawer;
  switch (DrawerCont) {
    case "EstudioBox":
      ContenidoDrawer = (
        <EstudioBox
          ShowInf={ShowInf}
          VerInforme={VerInforme}
          SeleccionarEstudio={SeleccionarEstudio}
          seleccionado={EstudioSeleccionado}
          estudio={JSON.parse(window.sessionStorage.getItem("EstudioVisor"))}
        />
      );
      break;
    default:
      ContenidoDrawer = (
        <EstudioBox
          ShowInf={ShowInf}
          VerInforme={VerInforme}
          SeleccionarEstudio={SeleccionarEstudio}
          seleccionado={EstudioSeleccionado}
          estudio={JSON.parse(window.sessionStorage.getItem("EstudioVisor"))}
        />
      );
      break;
  }
  useEffect(() => {
    funciones.BuscarInforme()
    .then(res=>{
        setPrintCode(new Date().getTime())
        res.visible = false
        if(res.existe){
            funciones.GetInforme(res.nombre)
            .then(PDFres=>{
                setPdfBlob(PDFres)
            })
        }
        setVerInforme({...res})
    })
  }, []);
  return (
    <div
      id="Visor"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <Cabeza
        {...props}
        onPlay={onPlay}
        Cinema={Cinema}
        onHerrDesSel={HerrDesSel}
        HerrDes={HerrDes}
        PrintCode={PrintCode}
      />
      <Drawer visible={DrawerVisible} VerDrawer={VerDrawer}>
        {ContenidoDrawer}
      </Drawer>
      <Main
        Estudio={Estudio}
        EstudioSeleccionado={EstudioSeleccionado}
        VerInforme={VerInforme.visible}
        Cinema={Cinema}
        onCinemaCrash={CinemaCrash}
        HerrMobil={HerrMobil}
        HerrDes={HerrDes}
        PdfBlob={PdfBlob}
        PrintCode={PrintCode}
      />
      <Pies
        onSistemAction={PiesSistemAction}
        onToolSelecctionMob={onToolSelecctionMob}
        Cinema={Cinema}
        HerrMobil={HerrMobil}
      />
      <Dialog />
    </div>
  );
}

export default Visor;
